import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import PageWrapper from '../components/pageWrapper'

const Newsletter = () => {
  return (
    <Layout>
      <PageWrapper style={{ background: '#fff' }}>
        <div className="d-flex jusitify-content-center flex-column align-items-center">
          <iframe
            width="650"
            style={{ margin: '0 auto' }}
            height="850"
            src="https://9d422095.sibforms.com/serve/MUIEADlxWlVniPXIaI18SlWj7PK1WcB1SAFGLLonguvUkOZT2olW3DHvSM-STTQ59ZP7IotAw_BxamR2wviZ7OJlUYEjaUewGLAXmrWTVvYUbRq7XUKqUvWBYlkt8m_T4YId3nXtAb4sFVqcEv2uzmWS1W98KYajxSkhIrN_eOKDO4C3pCTjEvu8jiqwIpdOeWsw6uSnYlf76md3"
            frameborder="0"
            scrolling="auto"
            allowfullscreen
          ></iframe>
        </div>
      </PageWrapper>
    </Layout>
  )
}

export default Newsletter
